import moment from "moment";
import {
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { PAYMENT_STATUS, PAYMENT_STATUS_BADGE_COLOR, PAYMENT_STATUS_DROPDOWN } from "../../../../types/payments";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Badge from "../../../../components/shared/Badge";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
} from "../../../../components/shared/Table/styled";
import {
  DropdownContainer,
  PaymentsTable,
  ManageContentContainer,
  TableBoldText,
  TableFadedText,
  TableHeadWithIcon,
} from "./styled";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import TablePagination from "../../../../components/shared/TablePagination";
import { setSelectedPayment, updatePaymentTableVariables } from "../../../../redux/slices/paymentSlice";
import { useGetPaymentListAdmin } from "../../../queries/payment";
import ActionsDropDown from "../../../../components/shared/ActionsDropDown";
import { FiltersContainer } from "../../../../components/people/Manage/styled";
import SearchInput from "../../../../components/shared/SearchInput";
import DropdownList from "../../../../components/shared/DropdownList";
import { useEffect, useState } from "react";
import { DropdownListOption } from "../../../../types/componentsProps";
import PaymentStatusModal from "../PaymentStatusModal";
import { Sort } from "../../../../components/shared/SortIcon";
import { useDebounce } from "../../../../hooks/useDebounce";

const PaymentsListAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userCompaniesOptions, allCompanies } = useAppSelector((state) => state.companies);
  const { 
    search,
    status,
    sortKey,  
    sortDirection, 
    page, 
    limit,
    totalPages,
    currentPage
  } = useAppSelector((state) => state.payment.payments_table_Variables);
  
  const [searchValue, setSearchValue] = useState<string>("");
  const debounceValue = useDebounce(searchValue);
  
  const [ selectedCompany, setSelectedCompany ] = useState<DropdownListOption>(userCompaniesOptions[0]);
  const [ openModal, setOpenModal ] = useState(false);
  
  const { data: paymentsList, refetch } = useGetPaymentListAdmin({
    company_id: allCompanies?.find(company => company?.name === selectedCompany.label)?.id,
    search,
    status: status === PAYMENT_STATUS_DROPDOWN[0] ? undefined : status.label,
    sort: sortKey,
    sort_dir: sortDirection,
    page: currentPage,
    limit,
  });

  useEffect(() => {
    dispatch(
      updatePaymentTableVariables({
        search: debounceValue,
        currentPage: 1
    }));
  }, [debounceValue]);

  useEffect(() => {
    if (paymentsList) {
      dispatch(
        updatePaymentTableVariables({
          currentPage: paymentsList._metadata.page,
          totalRecords: paymentsList._metadata.total_records,
          totalPages: paymentsList._metadata.last_page,
        })
      );
    }
  }, [paymentsList]);

  useEffect(() => {
    return function() {
      dispatch(updatePaymentTableVariables({
        sortDirection: "ASC",
        sortKey: "public_id",
        currentPage: 1,
        status: PAYMENT_STATUS_DROPDOWN[0]
      }))
    }
  }, [])

  const renderRows = () => {
    return paymentsList?.payments?.map((payment: any) => (
      <TableRow key={payment.id}>
        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {payment.public_id}
          </TableBoldText>
        </TableCell>
        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {payment.company.name}
          </TableBoldText>
        </TableCell>
        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {payment.transaction_id}
          </TableBoldText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {payment.invoice_count}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {currencyFormatter(payment.value, "USD")}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {currencyFormatter(payment.confirmed_value, "USD")}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <Badge
            text={payment.status}
            dot
            color={PAYMENT_STATUS_BADGE_COLOR[payment.status]}
          />
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {moment(payment.created_at).format("DD MMM, YYYY")}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <ActionsDropDown
            actions={[
              {
                text: "View Details",
                onSelect: () => {
                  dispatch(setSelectedPayment(payment));
                  navigate(`/payment/payment-details/${payment.id}`);
                }
              },
              {
                text: "Change/Update Status",
                onSelect: () => {
                  if(payment.status === PAYMENT_STATUS.pending_confirmation) {
                    dispatch(setSelectedPayment(payment));
                    setOpenModal(true);
                  }
                },
              },
            ]}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <ManageContentContainer>
      <FiltersContainer>
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search for transaction number or payment ID"
          containerStyle={{ width: "36.5%" }}
        />
        <DropdownContainer>
          <DropdownList
            options={userCompaniesOptions}
            selectedOption={selectedCompany}
            setSelectedOption={(company: DropdownListOption) => {
              dispatch(
                updatePaymentTableVariables({
                  currentPage: 1
              }));
              setSelectedCompany(company)
            }}
            style={{ height: "48px", marginTop: "0px" }}
          />
          <DropdownList
            options={PAYMENT_STATUS_DROPDOWN}
            selectedOption={status}
            setSelectedOption={(status) => {
              dispatch(
                updatePaymentTableVariables({
                  status,
                  currentPage: 1,
                })
              );
            }}  
            style={{ height: "48px", marginTop: "0px" }}
          />
        </DropdownContainer>
      </FiltersContainer>

      <PaymentsTable>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "public_id" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "public_id",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Payment ID
              </TableFadedText>
              <Sort
                sortKey={"public_id"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "company" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "company",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Company
              </TableFadedText>
              <Sort
                sortKey={"company"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "transaction_id" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "transaction_id",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Transaction #
              </TableFadedText>
              <Sort
                sortKey={"transaction_id"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Number of Invoices
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "value" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "value",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Value
              </TableFadedText>
              <Sort
                sortKey={"value"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "confirmed_value" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "confirmed_value",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Confirmed Value
              </TableFadedText>
              <Sort
                sortKey={"confirmed_value"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "status" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "status",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Status
              </TableFadedText>
              <Sort
                sortKey={"status"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortDirection: sortKey !== "created_at" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "created_at",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Payment Date
              </TableFadedText>
              <Sort
                sortKey={"created_at"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
        </TableHeader>
        {renderRows()}
        {paymentsList && paymentsList.payments.length > 0 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(updatePaymentTableVariables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </PaymentsTable>
      <PaymentStatusModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </ManageContentContainer>
  )
};

export default PaymentsListAdmin;