export const COMPANIES_PAYROLLS = [
  {
    id: 2,
    cycleStartDate: "2024-08-01",
    processingDate: "2024-08-11",
    payrollCycle: {
      start_date: "2024-08-01",
      end_date: "2024-08-31",
      payment_due_date: "2024-07-25",
      payroll_processing_date: "2024-08-31",
      review_deadline: "2024-07-20",
      pay_day: "2024-08-31",
    },
    createdAt: "2020-10-10",
    status: "Pending Review",
    company: {
      id: "2",
      name: "Interlock - UAE",
    },
    total_currency: "USD",
    total: 2000,
  },
];

export const PAYROLLS_REVIEWS_EMPLOYEES = [
  {
    id: 1,
    first_name: "Jenny",
    last_name: "Wilson",
    salary_net: 32000,
    salary_gross: 33000,
    status: "Active",
    currency: "USD",
    insurance: 1000,
    taxes: 500,
    contract_salary: 33000,
    total_deductions: 0,
    employee: {
      id: 1,
      user: {
        id: 1,
        email: "soli+2@ovarc.io",
      },
    },
    pay_period_start: "2023-11-02",
    pay_period_end: "2023-11-15",
    contract: {
      id: 1,
      status: "Active",
      department: "Software Engineering",
      job_title: "Senior Developer",
    },
    bonuses: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
    reductions: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
  },
  {
    id: 2,
    first_name: "Jenny",
    last_name: "Green",
    salary_net: 2000,
    salary_gross: 10000,
    status: "Active",
    currency: "USD",
    insurance: 1000,
    taxes: 500,
    contract_salary: 33000,
    total_deductions: 0,
    employee: {
      id: 1,
      user: {
        id: 1,
        email: "soli+3@ovarc.io",
      },
    },
    pay_period_start: "2023-11-02",
    pay_period_end: "2023-11-15",
    contract: {
      id: 1,
      status: "Active",
      department: "Software Engineering",
      job_title: "Senior Developer",
    },
    bonuses: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
    reductions: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
  },
  {
    id: 3,
    first_name: "Jenny",
    last_name: "Blue",
    salary_net: 10000,
    salary_gross: 33000,
    status: "Active",
    currency: "USD",
    insurance: 1000,
    taxes: 500,
    contract_salary: 33000,
    total_deductions: 0,
    employee: {
      id: 1,
      user: {
        id: 1,
        email: "soli+24@ovarc.io",
      },
    },
    pay_period_start: "2023-11-02",
    pay_period_end: "2023-11-15",
    contract: {
      id: 1,
      status: "Active",
      department: "Software Engineering",
      job_title: "Senior Developer",
    },
    bonuses: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
    reductions: [
      {
        name: "Holiday Bonus",
        amount: 150,
      },
      {
        name: "Performance Bonus",
        amount: 200,
      },
    ],
  },
];

export const PAYROLLS_DETAILS = [
  {
    id: 2,
    status: "Pending Review",
    worker_type: "Contractor",
    submission_due_date: "2024-07-20",
    processed_at: "2024-09-20",
    submitted_at: "2024-08-14",
    rejection_reason: null,
    created_at: "2024-08-11",
    updated_at: "2024-08-14",
    proof_of_payment_id: null,
    cycle_id: 1,
    submitted_by_id: null,
    processed_by_id: null,
    company_id: 1,
    invoice_id: null,
    total: 3852.29,
    total_net: 4965.84,
    total_currency: "USD",
    payroll_employee_count: 5,
    payrollCycle: {
      start_date: "2024-08-01",
      end_date: "2024-08-31",
      payment_due_date: "2024-07-25",
      payroll_processing_date: "2024-08-31",
      review_deadline: "2024-07-20",
      pay_day: "2024-08-31",
    },
    company: {
      id: "2",
      name: "Interlock - UAE",
    },
  },
];

export const PAYROLL_CYCLE_CURRENT_UPCOMING = {
  id: 1,
  status: "New",
  worker_type: "EOR Employee",
  submission_due_date: "2024-07-19",
  processed_at: null,
  submitted_at: null,
  rejection_reason: null,
  created_at: "2024-08-17",
  updated_at: "2024-08-17",
  proof_of_payment_id: null,
  cycle_id: 1,
  submitted_by_id: null,
  processed_by_id: null,
  company_id: 1,
  payrollCycle: {
    start_date: "2024-08-01",
    end_date: "2024-08-30",
    payment_due_date: "2024-07-25",
    payroll_processing_date: "2024-08-30",
    review_deadline: "2024-07-20",
  },
  company: {
    id: 1,
    name: "tes",
  },
};

export const PAYROLL_CYCLE_UPCOMING_MOCK = {
  id: 4,
  status: "Pending Review",
  worker_type: "EOR Employee",
  submission_due_date: "2024-08-19",
  processed_at: null,
  submitted_at: null,
  rejection_reason: null,
  created_at: "2024-08-17",
  updated_at: "2024-08-17",
  proof_of_payment_id: null,
  cycle_id: 2,
  submitted_by_id: null,
  processed_by_id: null,
  company_id: 1,
  payrollCycle: {
    start_date: "2024-09-01",
    end_date: "2024-09-30",
    payment_due_date: "2024-08-25",
    payroll_processing_date: "2024-09-30",
    review_deadline: "2024-08-20",
  },
  company: {
    id: 1,
    name: "tes",
  },
};
