import {
  Asset,
  Employee,
  EmployeeDetails,
  Payslip,
  PayslipOverView,
} from "../../types/people";

export const EMPLOYEES: Employee[] = [
  {
    id: "1",
    name: "Jenny Wilson",
    first_name: "Jenny",
    last_name: "Wilson",
    email: "jane@interlock.io",
    title: "UI/UX Designer",
    contract_status: "Active",
    currency: "USD",
    contract_id: "1",
    end_date: "2025-11-02",
    created_at: "2024-03-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "2",
    contract_status: "Active",
    end_date: "2025-11-02",
    name: "Phoenix Baker",
    first_name: "Phoenix",
    last_name: "Baker",
    email: "phoenix@interlock.io",
    title: "Software Developer",
    currency: "USD",
    contract_id: "2",
    created_at: "2023-12-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "3",
    contract_status: "Active",
    end_date: "2025-11-02",
    name: "Lana steiner",
    first_name: "Lana",
    last_name: "Steiner",
    email: "lana@interlock.io",
    title: "Scrum Master",
    currency: "USD",
    contract_id: "3",
    created_at: "2024-03-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "4",
    contract_status: "Active",
    end_date: "2025-11-02",
    name: "Demi Wilkinson",
    first_name: "Demi",
    last_name: "Wilkinson",
    email: "demi@interlock.io",
    currency: "USD",
    title: "Ethical Hacker",
    contract_id: "4",
    created_at: "2023-03-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "5",
    contract_status: "Active",
    end_date: "2024-09-02",
    name: "Candice Wu",
    first_name: "Candice",
    last_name: "Wu",
    email: "candice@interlock.io",
    currency: "USD",
    title: "Software Tester",
    contract_id: "5",
    created_at: "2024-01-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "6",
    contract_status: null,
    end_date: "2025-11-02",
    name: "Natali Craig",
    first_name: "Natali",
    last_name: "Craig",
    email: "natali@interlock.io",
    currency: "USD",
    title: "Team Leader",
    contract_id: null,
    created_at: "2024-07-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },

  {
    id: "7",
    contract_status: null,
    end_date: "2025-11-02",
    name: "Drew Cano",
    first_name: "Drew",
    last_name: "Cano",
    email: "drew@interlock.io",
    currency: "USD",
    title: "Software Development",
    contract_id: null,
    created_at: "2024-08-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    id: "8",
    contract_status: null,
    end_date: "2025-11-02",
    name: "Olivia Rye",
    first_name: "Olivia",
    last_name: "Rye",
    email: "olivia@untitledui.com",
    currency: "USD",
    title: "Web Designer",
    contract_id: null,
    created_at: "2024-02-01",
    employment_term: 12,
    company: {
      id: 1,
      name: "Ovarc"
    }
  },
  {
    "id": "9",
    "name": "Jenny Test",
    "first_name": "Jenny",
    "last_name": "Test",
    "email": "janetest@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Onboarding",
    "currency": "USD",
    "contract_id": "1",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  },
  {
    "id": "10",
    "name": "Jenny Smith",
    "first_name": "Jenny",
    "last_name": "Smith",
    "email": "jennysmith@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Onboarding",
    "currency": "USD",
    "contract_id": "2",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  },
  {
    "id": "11",
    "name": "Jenny Brown",
    "first_name": "Jenny",
    "last_name": "Brown",
    "email": "jennybrown@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Onboarding",
    "currency": "USD",
    "contract_id": "3",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  },
  {
    "id": "12",
    "name": "Jenny Green",
    "first_name": "Jenny",
    "last_name": "Green",
    "email": "jennygreen@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Offboarding",
    "currency": "USD",
    "contract_id": "4",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  },
  {
    "id": "13",
    "name": "Jenny White",
    "first_name": "Jenny",
    "last_name": "White",
    "email": "jennywhite@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Offboarding",
    "currency": "USD",
    "contract_id": "5",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  },
  {
    "id": "14",
    "name": "Jenny Blue",
    "first_name": "Jenny",
    "last_name": "Blue",
    "email": "jennyblue@interlock.io",
    "title": "UI/UX Designer",
    "contract_status": "Offboarding",
    "currency": "USD",
    "contract_id": "6",
    "end_date": "2025-11-02",
    "created_at": "2024-03-01",
    "employment_term": 12,
    "company": {
      "id": 1,
      "name": "Ovarc"
    }
  }  
];

export const EMPLOYEES_DETAILS: EmployeeDetails[] = [
  {
    id: 1,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 1,
      first_name: "Jenny",
      last_name: "Wilson",
      email: "jane@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: {
      id: 1,
      department: "Product",
      job_title: "UI/UX Designer",
      employment_term: 3,
      hours_per_week: 40,
      start_date: "2023-11-02",
      end_date: "2025-11-02",
      employment_type: "Full-time",
      job_description: "UI/UX Designer",
      salary_net: 1200,
      salary_type: "Net",
      status: "Active",
      salary_gross: 1340,
      probation_period: 90,
      probation_last_day: "2024-06-01",
      created_at: "2024-07-22T12:50:15.000Z",
      currency: "USD",
      auto_renew: true,
    },
  },
  {
    id: 2,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 2,
      first_name: "Phoenix",
      last_name: "Baker",
      email: "phoenix@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: {
      id: 2,
      department: "Tech",
      job_title: "Software Developer",
      employment_term: 3,
      hours_per_week: 40,
      start_date: "2023-11-02",
      end_date: "2025-11-02",
      employment_type: "Full-time",
      job_description: "Software Developer",
      salary_net: 1200,
      salary_type: "Net",
      status: "Active",
      salary_gross: 1340,
      probation_period: 90,
      probation_last_day: "2024-06-01",
      created_at: "2024-07-22T12:50:15.000Z",
      currency: "USD",
      auto_renew: true,
    },
  },
  {
    id: 3,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 3,
      first_name: "Lana",
      last_name: "Steiner",
      email: "lana@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: {
      id: 3,
      department: "Tech",
      job_title: "Scrum Master",
      employment_term: 3,
      hours_per_week: 40,
      start_date: "2023-11-02",
      end_date: "2025-11-02",
      employment_type: "Full-time",
      job_description: "Scrum Master",
      salary_net: 1200,
      salary_type: "Net",
      status: "Active",
      salary_gross: 1340,
      probation_period: 90,
      probation_last_day: "2024-06-01",
      created_at: "2024-07-22T12:50:15.000Z",
      currency: "USD",
      auto_renew: true,
    },
  },
  {
    id: 4,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 4,
      first_name: "Demi",
      last_name: "Wilkinson",
      email: "demi@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: {
      id: 4,
      department: "Tech",
      job_title: "Ethical Hacker",
      employment_term: 3,
      hours_per_week: 40,
      start_date: "2023-11-02",
      end_date: "2025-11-02",
      employment_type: "Full-time",
      job_description: "Ethical Hacker",
      salary_net: 1200,
      salary_type: "Net",
      status: "Active",
      salary_gross: 1340,
      probation_period: 90,
      probation_last_day: "2024-06-01",
      created_at: "2024-07-22T12:50:15.000Z",
      currency: "USD",
      auto_renew: true,
    },
  },
  {
    id: 5,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 5,
      first_name: "Candice",
      last_name: "Wu",
      email: "candice@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: {
      id: 5,
      department: "Tech",
      job_title: "Software Tester",
      employment_term: 3,
      hours_per_week: 40,
      start_date: "2023-11-02",
      end_date: "2024-09-02",
      employment_type: "Full-time",
      job_description: "Software Tester",
      salary_net: 1200,
      salary_type: "Net",
      status: "Active",
      salary_gross: 1340,
      probation_period: 90,
      probation_last_day: "2024-06-01",
      created_at: "2024-07-22T12:50:15.000Z",
      currency: "USD",
      auto_renew: true,
    },
  },
  {
    id: 6,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 6,
      first_name: "Natali",
      last_name: "Craig",
      email: "natali@interlock.io",
      verified: true,
      type: "Employee",
    },
    contract: null,
  },
  {
    id: 7,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 7,
      first_name: "Drew",
      last_name: "Cano",
      email: "drew@interlock.io",
      verified: false,
      type: "Employee",
    },
    contract: null,
  },
  {
    id: 8,
    type_of_worker: "EOR Employee",
    work_from_country_id: 52,
    nationality_id: 52,
    nationality: "Egypt",
    company: {
      id: 0,
      name: "Ovarc",
    },
    user: {
      id: 8,
      first_name: "Olivia",
      last_name: "Rye",
      email: "olivia@untitledui.com",
      verified: false,
      type: "Employee",
    },
    contract: null,
  },
];

export const ASSETS = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: "80%",
    employeeStatus: "In-Active",
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: "60%",
    employeeStatus: "In-Active",
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    employeeStatus: "In-Active",
    brand: "Epson",
    condition: "100%",
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",
    employeeStatus: "In-Active",
    brand: "Polycom",
    condition: "70%",
  },
];

export const EmployeeAssets: {
  user_id: string;
  assets: Asset[];
}[] = [
  {
    user_id: "1",
    assets: [],
  },
  {
    user_id: "2",
    assets: [],
  },
  {
    user_id: "3",
    assets: [],
  },
  {
    user_id: "4",
    assets: [],
  },
  {
    user_id: "5",
    assets: [],
  },
];
export const EMPLOYEE_PAYSLIPS_OVERVIEW: PayslipOverView[] = [
  {
    id: "1",
    pay_period_start: "2024-01-01",
    pay_period_end: "2024-01-15",
    payment_date: "2024-01-16",
    salary_gross: "3200",
    salary_net: "2700",
    currency: "USD",
  },
  {
    id: "2",
    pay_period_start: "2024-01-17",
    pay_period_end: "2024-01-30",
    payment_date: "2024-01-31",
    salary_gross: "3200",
    salary_net: "2700",
    currency: "USD",
  },
  {
    id: "3",
    pay_period_start: "2024-02-01",
    pay_period_end: "2024-02-15",
    payment_date: "2024-02-16",
    salary_gross: "3200",
    salary_net: "2700",
    currency: "USD",
  },
  {
    id: "4",
    pay_period_start: "2024-02-17",
    pay_period_end: "2024-02-28",
    payment_date: "2024-02-29",
    salary_gross: "3200",
    salary_net: "2700",
    currency: "USD",
  },
  {
    id: "5",
    pay_period_start: "2024-03-01",
    pay_period_end: "2024-03-15",
    payment_date: "2024-03-16",
    salary_gross: "3200",
    salary_net: "2700",
    currency: "USD",
  },
];

export const ONBOARDING_OFFBOARDING_STATUS_DASHBOARD = {
  Onboarding: 7,
  Offboarding: 2,
};

export const PAYSLIPS = [
  {
    id: "1",
    pay_period_start: "2024-01-01",
    pay_period_end: "2024-01-15",
    payment_date: "2024-01-16",
    first_name: "Craig",
    last_name: "David",
    email: "c.david@interlock.io",
    job_title: "Graphic designer",
    currency: "USD",
    salary_gross: "3200",
    total_deductions: "500",
    salary_net: "2700",
    contract_salary_gross: "9000",
    total_bonuses: "500",
    taxes: "200",
    insurance: "100",
  },
  {
    id: "2",
    pay_period_start: "2024-01-17",
    pay_period_end: "2024-01-30",
    payment_date: "2024-01-31",
    currency: "USD",
    salary_gross: "3200",
    total_deductions: "500",
    salary_net: "2700",
    contract_salary_gross: "2500",
    total_bonuses: "500",
    first_name: "Craig",
    last_name: "David",
    email: "c.david@interlock.io",
    job_title: "Graphic designer",
    insurance: "100",
    taxes: "200",
  },
  {
    id: "3",
    pay_period_start: "2024-02-01",
    pay_period_end: "2024-02-15",
    payment_date: "2024-02-16",
    currency: "USD",
    salary_gross: '3200',

    total_deductions: '500',

    salary_net: '2700',
    contract_salary_gross: '2500',
    total_bonuses: '500',
    first_name: "Craig",
    last_name: "David",
    email: "c.david@interlock.io",
    job_title: "Graphic designer",
    insurance: "100",
    taxes: '200',
  },
  {
    id: "4",
    first_name: "Craig",
    last_name: "David",
    email: "c.david@interlock.io",
    job_title: "Graphic designer",
    insurance: "100",
    pay_period_start: "2024-02-17",
    pay_period_end: "2024-02-28",
    payment_date: "2024-02-29",
    currency: "USD",
    salary_gross: '3200',

    total_deductions: '500',

    salary_net: '2700',
    contract_salary_gross: '2500',
    total_bonuses: '500',


    taxes: '200',
  },
  {
    id: "5",
    pay_period_start: "2024-03-01",
    pay_period_end: "2024-03-15",
    payment_date: "2024-03-16",
    currency: "USD",
    salary_gross: '3200',
    total_deductions: '500',
    salary_net: '2700',
    contract_salary_gross: '2500',
    total_bonuses: '500',
    first_name: "Craig",
    last_name: "David",
    email: "c.david@interlock.io",
    job_title: "Graphic designer",
    insurance: "100",
    taxes: '200',
  },
];

export const EMPLOYEE_REQUESTS_LIST = [
  {
    id: 1,
    description: "Family Vacation",
    type: "Vacation request",
    status: "Rejected",
    date: "2023-11-02",
  },
  {
    id: 2,
    description: "Work visa",
    type: "Document Renewal",
    status: "Pending",
    date: "2023-11-02",
  },
  {
    id: 3,
    description: "Driver's license",
    type: "Required Document",
    status: "Delivered",
    date: "2023-11-02",
  },
];

export const EMPLOYEE_REQUESTS_Details = [
  {
    id: 1,
    description: "Family Vacation",
    type: "Vacation request",
    status: "Rejected",
    date: "2023-11-02",
    startDate: "2023-11-02",
    endDate: "2023-11-13",
    reasonOfRejection: "Inadequate Notice",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
      {
        date: "2023-11-04",
        status: "Rejected",
        by: "Amanda Webb",
      },
    ],
  },
  {
    id: 2,
    description: "Work visa",
    type: "Document Renewal",
    status: "Pending",
    date: "2023-11-02",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
    ],
  },
  {
    id: 3,
    description: "Driver's license",
    type: "Required Document",
    status: "Delivered",
    date: "2023-11-02",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
      {
        date: "2023-11-04",
        status: "Delivered",
        by: "Amanda Webb",
      },
    ],
  },
];

export const EMPLOYEE_BENEFITS = [
  {
    id: 1,
    provider: "Allianz",
    type: "Health Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 199,
      currency: "USD",
      coveragePeriod: "month",
    },
  },

  {
    id: 2,
    provider: "Allianz",
    type: "Vision Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 99,
      currency: "USD",
      coveragePeriod: "month",
    },
  },
  {
    id: 3,
    provider: "Allianz",
    type: "Dental Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 99,
      currency: "USD",
      coveragePeriod: "month",
    },
  },
];

export const PEOPLE_TO_ONBOARD = [
  {
    id: "1",
    type: "EOR",
    start_date: "2024-11-02",
    status: "Onboarding",
    created_at: "2024-08-22",
    job_title: "Designer",
    employee: {
      id: 9,
      type_of_worker: "EOR Employee",
      work_from: "Egypt",
      nationality: "Egypt",
      user: {
          id: 13,
          first_name: "Ali",
          last_name: "Ali",
      }
    }
  },
  // {
  //   id: "2",
  //   first_name: "Courtney",
  //   last_name: "Henry",
  //   type: "EOR",
  //   start_date: "2023-11-02",
  //   status: "invited",
  //   signedUp: false,
  //   invitationDate: "2023-10-25",

  //   job_title: "Designer",
  //   country_of_citizenship: "Egypt",
  // },
  // {
  //   id: "3",
  //   first_name: "Jacob",
  //   last_name: "Jones",
  //   type: "EOR",
  //   joining_date: "2023-11-02",
  //   status: "invited",
  //   signedUp: false,
  //   invitationDate: "2023-10-25",

  //   position: "Designer",
  //   country_of_citizenship: "Egypt",
  //   payroll_frequency: "Monthly",
  // },
];

export const PEOPLE_TO_OFFBOARD = [
  {
    id: "1",
    type: "EOR",
    start_date: "2024-11-02",
    status: "Invited",
    created_at: "2024-08-22",
    job_title: "Designer",
    employee: {
      id: 9,
      type_of_worker: "EOR Employee",
      work_from: "Egypt",
      nationality: "Egypt",
      user: {
          id: 13,
          first_name: "Ali",
          last_name: "Ali",
      }
    }
  }
];

export const JOB_HIERARCHY = [
  {
    category: "Software Development",
    description: "Software Development",
    level: "a",
    subcategory: "Junior Software Developer",
    role: "Software Developer",
  },
  {
    category: "Software Development",
    description: "Software Development b ",
    level: "b",
    subcategory: "senior Software Developer",
    role: "Data analyst",
  },
  {
    category: "Sales",
    description: "sales person",
    level: "a",
    subcategory: "Sales Associate",
    role: "Sales Associate",
  },
];
