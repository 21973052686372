export const URLS_TO_PASS_THROUGH =
  process.env.REACT_APP_ENV === "dev"
    ? [
        "/login",
        "/country",
        "/register",
        "/verify_email",
        "/resend_email",
        "/job_hierarchy",
        "/user",
        "/company",
        "company_employees/",
        "/employee",
        "/employee_contract_onboarding",
        "/employee_contract_cancel",
        "/payslip_employee/",
        "/payslip/",
        "/payslip_download/",
        "/payrollCycle",
        "/payment/pending",
        "/employees/getOnBoardingAndOffBoardingEmployeesDashboard",
        "/dashboard/payrollCycle",
        "/dashboard/payroll_cycle",
        "/dashboard/payment/pending",
        "/dashboard/employees",
        "/dashboard/upcomingActions",
        "/invoices_due?",
        "/invoice_details/",
        "/invoice_download/",
        "/current_balance/",
        "/payment",
        "/contract_quote",
        "/internal_bank_account/",
        "/cancel_autorenew",
        "/organization",
        "/forgot_password",
        "/payroll?",
        "/payroll/",
        "/payroll_employee",
        "/payroll_submit/",
        "/payslip_download_all/",
        "/admin/confirm_payment",
        "/admin/payment",
        "/admin/employees",
        "/admin/update_employee",
        "/admin/company",
        "/admin/add_employee_bank_account",
        "/admin/invoice_details",
        "/admin/payroll",
        "/admin/payroll_employee_csv",
        "/admin/process_payroll",
        "/refresh"
      ]
    : [];
