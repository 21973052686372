import {
  INVOICES_LIST,
  PAYMENTS,
  PAYMENTS_LIST,
  PENDING_PAYMENTS_DASHBOARD,
} from "../mockServer/MockData";
import { BadgeTypes } from "./componentsProps";

export type Payment = (typeof PAYMENTS)[0];

export type Invoice = (typeof INVOICES_LIST)[0];
export type UserPayment = (typeof PAYMENTS_LIST)[0];
export enum PAYMENT_STATUS {
  pending_confirmation = "Pending Confirmation",
  failed = "Failed",
  discrepant = "Discrepant",
  confirmed = "Confirmed",
  cancelled = "Cancelled"
}

export const PAYMENT_STATUS_DROPDOWN = [
  { label: "All Statuses", value: null },
  { label: "Pending Confirmation", value: "Pending Confirmation" },
  // { label: "Cancelled", value: "Cancelled" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Discrepant", value: "Discrepant" },
  { label: "Failed", value: "Failed" },
]

export const PAYMENT_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [PAYMENT_STATUS.cancelled]: BadgeTypes.error,
  [PAYMENT_STATUS.confirmed]: BadgeTypes.success,
  [PAYMENT_STATUS.discrepant]: BadgeTypes.warning,
  [PAYMENT_STATUS.failed]: BadgeTypes.error,
  [PAYMENT_STATUS.pending_confirmation]: BadgeTypes.blue,
};

export enum INVOICE_STATUS {
  paid = "Paid",
  cancelled = "Cancelled",
  pending_payment = "Pending Payment",
  pending_confirmation = "Pending Confirmation",
}

export const INVOICE_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [INVOICE_STATUS.cancelled]: BadgeTypes.error,
  [INVOICE_STATUS.paid]: BadgeTypes.success,
  [INVOICE_STATUS.pending_confirmation]: BadgeTypes.warning,
  [INVOICE_STATUS.pending_payment]: BadgeTypes.orange,
};

export type PendingPaymentsDashboard = {
  id: number,
  status: string,
  created_at: Date | string,
  method: string,
  value: number,
}[] | typeof PENDING_PAYMENTS_DASHBOARD;

export type Balance = {
  id: number;
  diff: number;
  current: number;
  currency: string;
} | null;

export interface BonusReduction {
  name: string;
  amount: number;
};

export interface InternalBankAccount {
  id: number,
  label: string,
  bank_name: string,
  account_number: string,
  iban: string,
  swift_code: string,
  currency: string
}

export interface InvoiceInterface {
  id: number,
  status: string,
  total: number,
  description: string,
  due_date: string,
  created_at: string,
  currency: string,
  type: string,
  public_id: string,
  company: {
    name: string,
    billing_address: string,
    registration_number: string,
  }
  line_items: {
    description: string,
    quantity: number,
    unit_price: number,
  }[] | null,
}

export interface PaymentInterface {
  id: number,
  notes: string;
  public_id: string,
  status: string,
  transaction_id: string,
  created_at: string,
  method: string,
  value: number,
  confirmed_value: number,
  confirmation_date: string,
  currency: string,
  invoice_count: number,
  invoices: InvoiceInterface[],
  company: {
    id: number,
    name: string
  },
  balance: Balance | null,
  internal_bank_account: InternalBankAccount | null,
}
