import { useEffect, useState } from "react";
import {
  emailRegex,
  typeOfWorkerOptions,
} from "../../../constants/registration";
import {
  ButtonsContainer,
  FormContainer,
  FormFields,
  FormRow,
  FormTitle,
  NewEmployeeHeader,
  NewEmployeeScreenContainer,
  PageSubtitle,
} from "./styled";
import { PageTitle } from "../Manage/styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import DropdownList from "../../shared/DropdownList";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import InputField from "../../shared/InputField";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { useGetUserCompanies } from "../../../queries/companies";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { createCompaniesDropdownOptions } from "../../../redux/slices/companiesSlice";
import { useGetCountries } from "../../../queries/auth";
import { createCountriesDropdownOptions } from "../../../redux/slices/appState";
import { useCreateEmployee } from "../../../queries/people";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { COUNTRIES_TO_WORK_FROM } from "../../../constants/dropDownLists";

const NewEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userCompaniesOptions } = useAppSelector((state) => state.companies);
  const { countriesOptions } = useAppSelector((state) => state.appState);
  const { activeCompany } = useAppSelector((state) => state.companies);
  const { data: companies, error: companiesError } = useGetUserCompanies();
  const { data: countries, error: countriesError } = useGetCountries();
  const { mutateAsync: createEmployee, isPending } = useCreateEmployee();
  const [formData, setFormData] = useState({
    company: userCompaniesOptions[0],
    type_of_worker: typeOfWorkerOptions.filter(
      (option) => !option.comingSoon
    )[0],

    first_name: "",
    last_name: "",
    email: "",
    nationality:
      countriesOptions.length > 0
        ? countriesOptions[0]
        : { label: "", value: "" },
    country: COUNTRIES_TO_WORK_FROM.filter((country) => !country.comingSoon)[0],
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const setFormDataPoint = (
    key: string,
    value: string | boolean | DropdownListOption
  ) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (companiesError || countriesError) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching data.",
        description: "Please try again later.",
      });
    }
  }, [companiesError, countriesError]);

  useEffect(() => {
    if (userCompaniesOptions.length > 0) {
      setFormDataPoint("company", userCompaniesOptions[0]);
    }
  }, [userCompaniesOptions]);

  useEffect(() => {
    if (countriesOptions.length > 0) {
      setFormDataPoint(
        "nationality",
        countriesOptions.filter((country) => country.label === "Egypt")[0]
      );
      setFormDataPoint(
        "country",
        countriesOptions.filter((country) => country.label === "Egypt")[0]
      );
    }
  }, [countriesOptions]);

  useEffect(() => {
    if (companies) {
      dispatch(createCompaniesDropdownOptions(companies));
    }
  }, [companies]);

  useEffect(() => {
    if (countries) {
      dispatch(createCountriesDropdownOptions(countries));
    }
  }, [countries]);

  const validateForm = () => {
    let isValid = true;
    if (formData.first_name.trim() === "") {
      setErrors((prev) => ({ ...prev, first_name: "First Name is required" }));
      isValid = false;
    }
    if (formData.last_name.trim() === "") {
      setErrors((prev) => ({ ...prev, last_name: "Last Name is required" }));
      isValid = false;
    }
    if (formData.email.trim() === "") {
      setErrors((prev) => ({ ...prev, email: "Email is required" }));
      isValid = false;
    }
    if (emailRegex.test(formData.email.trim()) === false) {
      setErrors((prev) => ({ ...prev, email: "Invalid email" }));
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (callBack: (id: string) => void) => {
    if (validateForm()) {
      try {
        const employee = await createEmployee({
          ...formData,
          company_id: activeCompany?.id.toString() || "",
          type_of_worker: formData.type_of_worker.label,
          nationality: formData.nationality.value,
          work_from_country: formData.country.value,
        });
        queryClient.invalidateQueries({
          queryKey: ["employees"],
        });
        callBack(employee.id || "");
      } catch (e: any) {
        if(e.response.data.message){
            const emailError: boolean = e.response.data.message.includes("Email already taken");
            if(emailError) {
                setErrors({...errors, email: "Email is taken"});
            }
        }
        showSnackbar({
          open: true,
          type: SnackbarTypes.ERROR,
          title: "Something went wrong",
          description: "Failed to create employee",
        });
      }
    }
  };

  return (
    <NewEmployeeScreenContainer>
      <NewEmployeeHeader>
        <PageTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          New Employee
        </PageTitle>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Add your new employee details.
        </PageSubtitle>
      </NewEmployeeHeader>
      <FormContainer>
        <FormTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          Employee details
        </FormTitle>
        <FormFields>
          {userCompaniesOptions.length > 0 && (
            <DropdownList
              label="Company"
              options={userCompaniesOptions}
              selectedOption={formData.company}
              setSelectedOption={(option) =>
                setFormDataPoint("company", option)
              }
            />
          )}

          <DropdownList
            label="Type of Worker"
            options={typeOfWorkerOptions}
            selectedOption={formData.type_of_worker}
            setSelectedOption={(option) =>
              setFormDataPoint("type_of_worker", option)
            }
          />

          <FormRow>
            <InputField
              label="First Name"
              value={formData.first_name}
              onChange={(e) => setFormDataPoint("first_name", e.target.value)}
              error={errors.first_name}
              onFocus={() => setErrors((prev) => ({ ...prev, first_name: "" }))}
              containerStyle={{ flex: 1 }}
            />
            <InputField
              label="Last Name"
              value={formData.last_name}
              onChange={(e) => setFormDataPoint("last_name", e.target.value)}
              error={errors.last_name}
              onFocus={() => setErrors((prev) => ({ ...prev, last_name: "" }))}
              containerStyle={{ flex: 1 }}
            />
          </FormRow>
          <InputField
            label="Email"
            value={formData.email}
            onChange={(e) => setFormDataPoint("email", e.target.value)}
            error={errors.email}
            onFocus={() => setErrors((prev) => ({ ...prev, email: "" }))}
            hint={
              "We will use this email for inviting your worker to complete their onboarding"
            }
          />

          <DropdownList
            label="Nationality"
            options={countriesOptions}
            selectedOption={formData.nationality}
            setSelectedOption={(option) =>
              setFormDataPoint("nationality", option)
            }
          />

          <DropdownList
            label="Country employee will be working from"
            options={COUNTRIES_TO_WORK_FROM}
            selectedOption={formData.country}
            setSelectedOption={(option) => setFormDataPoint("country", option)}
          />
        </FormFields>
        <ButtonsContainer>
          <Button
            text="Submit & Create Contract"
            variant={ButtonVariants.secondary_color}
            size={ButtonSizes.xl}
            onClick={() =>
              handleSubmit((id: string) =>
                navigate(`/people/profile/${id}/employee-contract`)
              )
            }
          />
          <Button
            text="Submit"
            variant={ButtonVariants.primary}
            size={ButtonSizes.xl}
            style={{ flex: 1 }}
            onClick={() =>
              handleSubmit((id: string) => navigate(`/people/profile/${id}`))
            }
            loading={isPending}
          />
        </ButtonsContainer>
      </FormContainer>
    </NewEmployeeScreenContainer>
  );
};

export default NewEmployee;
